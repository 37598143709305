import React from 'react';

import ChartDetail from '../../containers/ChartDetail';
import airGrade from '../../data/airGrade.json';

interface ChartDetailType {
  air: any;
}

const Pm1 = (props: ChartDetailType) => {
  const airPm1 = props.air.pm1;
  const title: string = '<b>PM1</b>';

  return (
    <div>
      <ChartDetail
        data={airPm1?.data}
        created_at={airPm1?.created_at}
        title={title}
        airGrade={airGrade.data.pm1}
      />
    </div>
  );
};

export default Pm1;

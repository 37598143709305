import React from 'react';

import ChartDetail from '../../containers/ChartDetail';
import airGrade from '../../data/airGrade.json';

interface ChartDetailType {
  air: any;
}

const Tvoc = (props: ChartDetailType) => {
  const airTvoc = props.air.tvoc;
  const title: string = '<b>TVOC</b>';
  // const airGrade: number[] = [0, 100, 250, 400, 520];
  return (
    <div>
      <ChartDetail
        data={airTvoc?.data}
        created_at={airTvoc?.created_at}
        title={title}
        airGrade={airGrade.data.tvoc}
      />
    </div>
  );
};

export default Tvoc;

import axios from 'axios';
import dayjs from 'dayjs';

const airDataApi = async () => {
  const date = new Date();

  const nowDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
  const widthCheck = window.innerWidth;
  const rangeMin = widthCheck <= 639 ? 4 : 8;

  const beforeDate = dayjs(
    new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes() - rangeMin
    )
  ).format('YYYY-MM-DDTHH:mm:ss');

  const response = await axios(
    `https://ischool1.askstoryboard.com/data?dev_id=6337&start_date=${beforeDate}&end_date=${nowDate}`
  );

  const { data } = response;
  return data;
};

export default airDataApi;

import React from 'react';

import ChartDetail from '../../containers/ChartDetail';
import airGrade from '../../data/airGrade.json';

interface ChartDetailType {
  air: any;
}

const Co2 = (props: ChartDetailType) => {
  const airCo2 = props.air.co2;
  const title: string = '<b>이산화탄소</b>';

  return (
    <div>
      <ChartDetail
        data={airCo2?.data}
        created_at={airCo2?.created_at}
        title={title}
        airGrade={airGrade.data.co2}
      />
    </div>
  );
};

export default Co2;

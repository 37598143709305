import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import airGrade from '../../data/airGrade.json';

interface ScoreType {
  airObject: any;
  indexNum: string;
}

const Score = (props: ScoreType) => {
  const { indexNum } = props;
  const { airObject } = props;
  // const airScore: any = airGrade;
  const airScore: any = airGrade.data;
  const airColorClass = airGrade.labelsColorName;
  const [airName, setAirName] = useState<string>('co2');
  const [airGradeColor, setAirGradeColor] = useState<string | null>(null);

  useEffect(() => {
    if (indexNum === '0') {
      setAirName('co2');
    } else if (indexNum === '1') {
      setAirName('temp');
    } else if (indexNum === '2') {
      setAirName('humi');
    } else if (indexNum === '3') {
      setAirName('pm1');
    } else if (indexNum === '4') {
      setAirName('pm25');
    } else if (indexNum === '5') {
      setAirName('tvoc');
    } else if (indexNum === '6') {
      setAirName('total_score');
    }
  }, [indexNum]);

  const forTotalScoreNum = -1;
  const scoreValue =
    airName === 'total_score'
      ? Number(Object.values(airObject[indexNum])) * forTotalScoreNum
      : Number(Object.values(airObject[indexNum]));

  useEffect(() => {
    for (let i = 0; i < 4; i += 1) {
      if (
        airScore[airName][i] <= scoreValue &&
        scoreValue <= airScore[airName][i + 1]
      ) {
        setAirGradeColor(airColorClass[i]);
        return;
      }
    }
  }, [airName]);

  const airValueColor: string = `text-[2rem] ${airGradeColor} `;
  const date = new Date();
  const nowDate = dayjs(date).format('YYYY년 MM월 DD일');
  const airNameForUi: string =
    (airName === 'co2' && '이산화탄소') ||
    (airName === 'temp' && '온도') ||
    (airName === 'humi' && '습도') ||
    (airName === 'total_score' && '통합환경지수') ||
    airName;

  return (
    <div className="grid w-5/12 text-[#FFFFFF] sm:w-screen">
      <div className="w-auto pt-6 text-center text-[2rem] sm:pb-7 sm:text-[1.7rem]">
        {nowDate}
      </div>
      <div className="w-auto pb-14 text-center text-[8rem]  ">
        <div
          className={`${airValueColor} mx-auto h-[25rem] w-[25rem]  rounded-[30rem] py-16 sm:mx-auto sm:h-[15rem] sm:w-[15rem]`}
        >
          <p className="text-[2.3rem] font-medium sm:text-[1.5rem]">
            {airNameForUi}
          </p>
          <p className="text-[8rem] font-bold sm:text-[3.5rem]">
            {Object.values(airObject[`${indexNum}`])}
            {airName === 'temp' && '°'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Score;

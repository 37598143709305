import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import React from 'react';
import Plot from 'react-plotly.js';

import airGrade from '../data/airGrade.json';

interface ChartDetailType {
  data: object[];
  created_at: object[];
  title: string;
  airGrade: number[];
}

dayjs.extend(utcPlugin);

const ChartDetail = (props: ChartDetailType) => {
  // 현재까지의 created_at, total_score의 값과 이후에 예상되는 created_at, total_score의 값
  let beforeDate: string | object[];

  let beforeCo2Score: number | object[];

  // x축과 y축에 값을 넣어줄 배열
  const xArray: string[] | string = [];
  const yArray: number[] | number = [];

  // data(x축의 값들과 y축의 값들을 포함한)
  const airData: object[] = [];

  const airQualityLength = props?.created_at?.length;

  for (let i = 0; i < airQualityLength; i += 1) {
    // 서버에서 날짜 받아오는 코드
    beforeDate = Object.values(props?.created_at[i]);
    beforeCo2Score = Object.values(props?.data[i]);

    // 서버에서 받아온 날짜 형식 바꾸는 코드
    const day = dayjs(beforeDate.toString()).utc().format('HH:mm');
    // console.log('day', day);

    // 현재까지의 값과 앞으로 예상되는 값

    // dayjs 통해 형식을 수정한 값을 배열로 넣어주기 위한 문장(airQuality)
    xArray.push(day);
    yArray.push(Number(beforeCo2Score));
  }

  // x축의 길이(airQuality와 future)의 전체 갯수(길이)를 보여주기 위함
  const xRange = props?.data?.length;

  // 반응형에 따라 선과 폰트의 크기 변화하는 문장

  // 현재 브라우저의 크기
  const nowWidth: number = window.innerWidth;

  // 그래프 폰트 size
  const graphFontSize: number = 12;
  // const graphPointFontSize: number = 28;
  const graphPointSize: number = 22;
  const labelSize: number = 7;
  const dataLineSize: number = 5;
  const titleFontSize: number = 13;
  const xTimeSize: number = 13;

  // plot margin
  const plotL: number = 47;
  const plotR: number = 25;
  const plotB: number = 40;
  const plotT: number = 55;

  // 그래프 현재 값 text 위치

  const airData1: number = props.airGrade[0];
  const airData2: number = props.airGrade[1];
  const airData3: number = props.airGrade[2];
  const airData4: number = props.airGrade[3];
  const airData5: number = props.airGrade[4];

  // 최근 값에 따른 airQuality 색상 변경 변수
  let airLineColor: string;
  const [good, average, bad, veryBad] = airGrade.labelsColor;

  // airQuality 현재값 기준으로 line color 설정
  // y축 라벨 color
  if (yArray[xArray.length - 1] <= airData2) {
    airLineColor = good;
  } else if (
    yArray[xArray.length - 1] > airData2 &&
    yArray[xArray.length - 1] <= airData3
  ) {
    airLineColor = average;
  } else if (
    yArray[xArray.length - 1] > airData3 &&
    yArray[xArray.length - 1] <= airData4
  ) {
    airLineColor = bad;
  } else if (
    yArray[xArray.length - 1] > airData4 &&
    yArray[xArray.length - 1] <= airData5
  ) {
    airLineColor = veryBad;
  }

  // y축 라벨 이름
  const labels = ['좋음', '보통', '나쁨', '위험'];

  const labelPositionLow: number = (airData2 - airData1) / 2 + airData1;
  const labelPositionMiddle: number = (airData3 - airData2) / 2 + airData2;
  const labelPositionMiddle2: number = (airData4 - airData3) / 2 + airData3;
  const labelPositionHigh: number = (airData5 - airData4) / 2 + airData4;

  // y축 라벨 위치
  const labelsPosition = [
    labelPositionLow,
    labelPositionMiddle,
    labelPositionMiddle2,
    labelPositionHigh,
  ];

  // 현재시간(airQuality)까지의 수치를 보여줌
  const airQualityData: object = {
    x: xArray.reverse(),
    y: yArray.reverse(),
    type: 'scatter',
    mode: 'lines',
    line: {
      width: nowWidth !== 0 && dataLineSize!,
      shape: 'spline',
      color: yArray[xArray.length - 1] !== 0 && airLineColor!,
    },
  };

  // 현재시간의 수치를 marker로 표현함
  const airQualityDataPoint: object = {
    x: [xArray[xArray.length - 1]],
    y: [yArray[xArray.length - 1]],
    type: 'scatter',
    mode: 'markers',
    marker: {
      color: 'white',
      size: nowWidth !== 0 && graphPointSize!,
    },
  };

  // 그래프에 들어갈 데이터들을 모아놓음
  airData.push(airQualityData, airQualityDataPoint);

  const divideMergedArray: string[] = [];

  // layout 코드

  const widthCheck = window.innerWidth;
  const rangeWidth = widthCheck <= 639 ? 340 : 532;

  const layout: any = {
    title: props.title,
    font: {
      color: '#acacac',
      size: nowWidth !== 0 && titleFontSize!,
    },
    paper_bgcolor: 'rgb(23,23,23)',
    plot_bgcolor: 'rgb(23,23,23)',
    autosize: true,
    showlegend: false,
    width: rangeWidth,
    height: 298,
    hovermode: false,
    // margin으로 plot의 크기 설정
    margin: {
      l: nowWidth !== 0 && plotL!,
      r: nowWidth !== 0 && plotR!,
      b: nowWidth !== 0 && plotB!,
      t: nowWidth !== 0 && plotT!,
      pad: 4,
    },

    // 그래프의 x축 layout을 담당
    xaxis: {
      range: [0, xRange],
      // x축으로 볼 수 있는 범위
      showline: false,
      showgrid: false,
      showticklabels: true,
      linecolor: 'rgb(71,71,71)',
      linewidth: 2,
      // autotick: false,
      ticks: '',
      tickfont: {
        family: 'NanumGothic',
        size: nowWidth !== 0 && xTimeSize!,
      },
      // fixedrange: true,
      tickvals: xArray,
      ticktext: divideMergedArray,
      fixedrange: true,
    },
    // 그래프의 y축 layout을 담당
    yaxis: {
      range: [airData1, airData5],
      // y축으로 볼 수 있는 범위
      showgrid: false,
      gridcolor: 'rgb(71, 71, 71)',
      zeroline: false,
      showline: false,
      showticklabels: false,
      type: 'linear',
      fixedrange: true,
    },

    shapes: [
      // y축 line 라벨
      {
        type: 'line',
        x0: 0,
        y0: airData1,
        x1: 47,
        y1: airData1,
        line: {
          color: 'rgb(71, 71, 71)',
          width: 1.5,
          dash: 'line',
        },
        layer: 'below',
        opacity: 0.6,
      },
      {
        type: 'line',
        x0: 0,
        y0: airData2,
        x1: 47,
        y1: airData2,
        line: {
          color: 'rgb(71, 71, 71)',
          width: 1.5,
          dash: 'line',
        },
        layer: 'below',
        opacity: 0.6,
      },
      {
        type: 'line',
        x0: 0,
        y0: airData3,
        x1: 47,
        y1: airData3,
        line: {
          color: 'rgb(71, 71, 71)',
          width: 1.5,
          dash: 'line',
        },
        layer: 'below',
        opacity: 0.6,
      },
      {
        type: 'line',
        x0: 0,
        y0: airData4,
        x1: 47,
        y1: airData4,
        line: {
          color: 'rgb(71, 71, 71)',
          width: 1.5,
          dash: 'line',
        },
        layer: 'below',
        opacity: 0.6,
      },
      {
        type: 'line',
        x0: 0,
        y0: airData5,
        x1: 47,
        y1: airData5,
        line: {
          color: 'rgb(71, 71, 71)',
          width: 1.5,
          dash: 'line',
        },
        layer: 'below',
        opacity: 0.6,
      },
      // label color line
      {
        type: 'line',
        x0: 0,
        y0: airData1,
        x1: 0,
        y1: airData2,
        line: {
          color: '#058ad1',
          width: nowWidth !== 0 && labelSize!,
          dash: 'line',
        },
      },
      {
        type: 'line',
        x0: 0,
        y0: airData2,
        x1: 0,
        y1: airData3,
        line: {
          color: '#1cb43e',
          width: nowWidth !== 0 && labelSize!,
          dash: 'line',
        },
      },
      {
        type: 'line',
        x0: 0,
        y0: airData3,
        x1: 0,
        y1: airData4,
        line: {
          color: '#c88920',
          width: nowWidth !== 0 && labelSize!,
          dash: 'line',
        },
      },
      {
        type: 'line',
        x0: 0,
        y0: airData4,
        x1: 0,
        y1: airData5,
        line: {
          color: '#cd2a29',
          width: nowWidth !== 0 && labelSize!,
          dash: 'line',
        },
      },
    ],
    annotations: [],
  };

  // label을 반복문을 통해 그래프에 보여줌
  for (let i = 0; i < labels.length; i += 1) {
    const result = {
      xref: 'paper',
      x: 0.008,
      y: labelsPosition[i],
      xanchor: 'right',
      yanchor: 'middle',
      text: `<b>${`${labels[i]}  `}</b>`,
      showarrow: false,
      font: {
        family: 'NanumGothic',
        size: nowWidth !== 0 && graphFontSize!,
        color: airGrade.labelsColor[i],
      },
    };

    layout.annotations.push(result);
  }

  const conFig = {
    editable: false,
    staticPlot: true,
    hovermode: 'x',
  };

  return (
    <div className="w-auto">
      <Plot data={airData} layout={layout} config={conFig} />
    </div>
  );
};

export default ChartDetail;

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import AirDataApi from '../../api/airDataApi';
import Co2 from './Co2';
import Humi from './Humi';
import Pm1 from './Pm1';
import Pm25 from './Pm25';
import Score from './Score';
import Temp from './Temp';
import Tvoc from './Tvoc';

const Dashboard = () => {
  const { data, isLoading, isError } = useQuery('data', AirDataApi);

  const airObject: object | any = {
    '0': data?.co2.data[data!.co2.data.length - 1],
    '1': data?.temp.data[data!.temp.data.length - 1],
    '2': data?.humi.data[data!.humi.data.length - 1],
    '3': data?.pm1.data[data!.pm1.data.length - 1],
    '4': data?.pm25.data[data!.pm25.data.length - 1],
    '5': data?.tvoc.data[data!.tvoc.data.length - 1],
    '6': data?.total_score.data[data!.total_score.data.length - 1],
  };

  const [indexNum, setIndexNum] = useState<string>('6');
  const handleAirIndex = (num: string) => {
    setIndexNum(num);
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // 부드러운 스크롤을 위해 'smooth' 옵션을 사용합니다.
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (indexNum !== '6') {
        setIndexNum('6');
      }
    }, 15000);
  }, [indexNum]);

  if (isLoading) {
    return (
      <div className="w-fullb h-[58.09rem] bg-[#171717]">
        <div className="mx-auto">Loading...</div>
      </div>
    );
  }

  if (isError) {
    return <div>Error occurred while fetching data</div>;
  }

  return (
    <div className="h-[59rem] w-full bg-[#171717] sm:h-full sm:w-screen">
      <div className="mx-auto flex w-11/12 sm:grid sm:w-full">
        <Score airObject={airObject} indexNum={indexNum} />
        <div className="float-right sm:w-screen ">
          <div className="w-[7/12] pt-3">
            <div className="flex pt-2 sm:grid">
              <div className="sm:0x-auto ml-5 mr-7 h-[19rem]  w-[30rem] overflow-hidden rounded-lg hover:border-2 sm:mx-0 sm:w-screen">
                <button
                  type="button"
                  className="transition-transform  duration-300 hover:scale-105 sm:w-screen"
                  onClick={() => handleAirIndex('0')}
                >
                  <Co2 air={data} />
                </button>
              </div>
              <div className="h-[19rem] w-[30rem] overflow-hidden rounded-lg hover:border-2 sm:mr-0  sm:w-screen">
                <button
                  type="button"
                  className="transition-transform  duration-300 hover:scale-105 sm:w-screen"
                  onClick={() => handleAirIndex('1')}
                >
                  <Temp air={data} />
                </button>
              </div>
            </div>
            <div className="flex sm:grid">
              <div className="ml-5 mr-7  h-[19rem] w-[30rem] overflow-hidden rounded-lg hover:border-2 sm:mx-0 sm:w-screen">
                <button
                  type="button"
                  className="transition-transform  duration-300 hover:scale-105 sm:w-screen"
                  onClick={() => handleAirIndex('2')}
                >
                  <Humi air={data} />
                </button>
              </div>
              <div className="h-[19rem] w-[30rem] overflow-hidden rounded-lg hover:border-2 sm:mr-0 sm:w-screen">
                <button
                  type="button"
                  className="transition-transform  duration-300 hover:scale-105 sm:w-screen"
                  onClick={() => handleAirIndex('3')}
                >
                  <Pm1 air={data} />
                </button>
              </div>
            </div>
            <div className="flex sm:grid">
              <div className="ml-5 mr-7  h-[19rem] w-[30rem] overflow-hidden rounded-lg hover:border-2 sm:mx-0 sm:w-screen">
                <button
                  type="button"
                  className="transition-transform  duration-300 hover:scale-105 sm:w-screen"
                  onClick={() => handleAirIndex('4')}
                >
                  <Pm25 air={data} />
                </button>
              </div>
              <div className="h-[19rem] w-[30rem] overflow-hidden rounded-lg hover:border-2 sm:mr-0 sm:w-screen">
                <button
                  type="button"
                  className="transition-transform  duration-300 hover:scale-105 sm:w-screen"
                  onClick={() => handleAirIndex('5')}
                >
                  <Tvoc air={data} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

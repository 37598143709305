import React from 'react';

import ChartDetail from '../../containers/ChartDetail';
import airGrade from '../../data/airGrade.json';

interface ChartDetailType {
  air: any;
}

const Pm25 = (props: ChartDetailType) => {
  const airPm25 = props.air.pm25;
  const title: string = '<b>PM25</b>';

  return (
    <div>
      <ChartDetail
        data={airPm25?.data}
        created_at={airPm25?.created_at}
        title={title}
        airGrade={airGrade.data.pm25}
      />
    </div>
  );
};

export default Pm25;

import './App.css';

import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Main from './pages/DashboardPage';
import Login from './pages/LoginPage';

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route element={<Login />} path="/" />
          <Route element={<Main />} path="/dashboard" />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;

import React from 'react';

import ChartDetail from '../../containers/ChartDetail';
import airGrade from '../../data/airGrade.json';

interface ChartDetailType {
  air: any;
}

const Temp = (props: ChartDetailType) => {
  const airTemp = props.air.temp;
  // console.log('efef', airTemp);
  const title: string = '<b>온도</b>';

  return (
    <div>
      <ChartDetail
        data={airTemp?.data}
        created_at={airTemp?.created_at}
        title={title}
        airGrade={airGrade.data.temp}
      />
    </div>
  );
};

export default Temp;
